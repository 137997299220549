import { ReactNode } from "react";
import { MakeDropdown } from "./components/MakeDropdown";
import { useTranslation } from "react-i18next";
import { OptionsButton } from "../../FilterComponents/OptionsButton";
import { useFilterValues } from "../../FilterComponents/useFilterValues";
import { FilterSlider } from "../../FilterComponents/FilterSlider";
import { CheckboxSection } from "./CheckboxSection";
import { IFilters } from "../../../../types";

const FilterSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex flex-col w-full mt-6">
      <div className="text-sm mb-2">{title}</div>
      <div className="flex flex-col">{children}</div>
    </div>
  );
};

export function RegularFilters({
  filters,
  updateFilters,
  resetFilters,
  backgroundColor = "light",
  currencySymbol
}: {
  backgroundColor?: "light" | "dark",
  filters: IFilters,
  updateFilters: (filters: Partial<IFilters>) => void,
  resetFilters: () => void,
  currencySymbol: string
}) {

  const { t } = useTranslation("evFinder");

  const {
    priceSliderProperties,
    availabilityOptions,
    topSpeedOptions,
    realRangeOptions,
    realConsumptionOptions,
    loadWeightOptions,
    loadVolumeOptions,
    seatsOptions,
  } = useFilterValues();


  return (
    <div className={`${backgroundColor === "dark" ? "text-white" : "text-black"} `}>
      <div className="hidden md:block text-lg">{t("filters.title")}</div>
      <FilterSection title={t("filters.make.label")}>
        <MakeDropdown
          backgroundColor={backgroundColor}
          value={filters.brands || []}
          updateValue={(value: string[] | null) => {
            updateFilters({ brands: value });
          }}
        />
      </FilterSection>
      <FilterSection title={t("filters.availability.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={availabilityOptions}
          value={filters.availabilityStatus}
          multipleOptions={true}
          onChange={(value: ("PAST" | "CURRENT" | "FUTURE")[] | null) => {
            console.log("Value: ", value);
            updateFilters({ availabilityStatus: value });
          }}
        />
      </FilterSection>
      <FilterSection title={t("filters.listPrice.label")}>
        <FilterSlider
          value={[
            filters.listPrice?.min,
            filters.listPrice?.max
          ]}
          onChange={(newVal: number[]) => updateFilters({ listPrice: { min: newVal[0], max: newVal[1] } })}
          min={priceSliderProperties.min}
          max={priceSliderProperties.max}
          step={priceSliderProperties.step}
          symbol={currencySymbol}
          backgroundColor={backgroundColor}
        />
      </FilterSection>
      <FilterSection title={t("filters.topSpeed.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={topSpeedOptions}
          value={filters.topSpeed}
          onChange={(value: 140 | 170 | 200 | 250 | null) => {
            updateFilters({ topSpeed: value });
          }}
        />
      </FilterSection>
      <FilterSection title={t("filters.realRange.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={realRangeOptions}
          value={filters.realRange}
          onChange={(value: 250 | 300 | 400 | 500 | null) => {
            updateFilters({ realRange: value });
          }}
        />
      </FilterSection>

      <FilterSection title={t("filters.realConsumption.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={realConsumptionOptions}
          value={filters.realConsumption}
          onChange={(value: 16 | 18 | 20 | 22 | null) => {
            updateFilters({ realConsumption: value });
          }}
        />
      </FilterSection>

      <hr className={`my-8 ${backgroundColor === "dark" ? "border-white " : "border-black"}`} />

      <FilterSection title={t("filters.loadWeight.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={loadWeightOptions}
          value={filters.loadWeight}
          onChange={(value: 400 | 500 | 600 | 700 | null) => {
            updateFilters({ loadWeight: value });
          }}
        />
      </FilterSection>

      <FilterSection title={t("filters.loadVolume.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={loadVolumeOptions}
          value={filters.loadVolume}
          onChange={(value: 350 | 400 | 500 | 700 | null) => {
            updateFilters({ loadVolume: value });
          }}
        />
      </FilterSection>

      <FilterSection title={t("filters.seats.label")}>
        <OptionsButton
          backgroundColor={backgroundColor}
          options={seatsOptions}
          value={filters.seats}
          onChange={(value: 2 | 4 | 5 | 7 | null) => {
            updateFilters({ seats: value });
          }}
        />
      </FilterSection>

      <hr className={`my-8 ${backgroundColor === "dark" ? "border-white " : "border-black"}`} />

      <div className="text-base my-6">
        {t("filters.vehicleFeatures.label")}
      </div>
      <CheckboxSection filters={filters} updateFilters={updateFilters} />

      <div className="flex w-full justify-center mt-5">
        <button
          className={
            "bg-white text-Blueberry-dark-default hover:bg-Blueberry-light-background w-full rounded py-1"
          }
          onClick={resetFilters}
        >
          {t("filters.resetButton")}
        </button>
      </div>
    </div>
  );
}
