import { useTranslation } from "react-i18next";
import { Tooltip } from "../Tooltip";



function PaginationArrowRight({ disabled, onClick }: { disabled: boolean, onClick: () => void }) {

    const { t } = useTranslation("evFinder");

    const color = disabled ? "#E1E4EB" : "#6F7582";

    return (
        <Tooltip content={<div>{t("pagination.nextPage")}</div>} placement={"bottom-end"} disabled={disabled}>
            <svg className={`${disabled ? '' : 'cursor-pointer'}`} onClick={() => {
                if (disabled) return;
                onClick()
            }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66663 3.33464L13.0186 9.68723C13.0599 9.72845 13.0927 9.77741 13.115 9.8313C13.1374 9.88519 13.1489 9.94296 13.1489 10.0013C13.1489 10.0596 13.1374 10.1174 13.115 10.1713C13.0927 10.2252 13.0599 10.2742 13.0186 10.3154L6.66663 16.668" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Tooltip>
    )
}


function PaginationArrowLeft({ disabled, onClick }: { disabled: boolean, onClick: () => void }) {

    const { t } = useTranslation("evFinder");
    const color = disabled ? "#E1E4EB" : "#6F7582";

    return (
        <Tooltip content={<div>{t("pagination.previousPage")}</div>} placement={"bottom-end"} disabled={disabled}>
            <svg className={`${disabled ? '' : 'cursor-pointer'}`} onClick={() => {
                if (disabled) return;
                onClick()
            }} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8334 17.1654L7.48137 10.8128C7.44009 10.7715 7.40734 10.7226 7.38499 10.6687C7.36265 10.6148 7.35115 10.557 7.35115 10.4987C7.35115 10.4404 7.36265 10.3826 7.38499 10.3287C7.40734 10.2748 7.44009 10.2258 7.48137 10.1846L13.8334 3.83203" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Tooltip>
    )

}



const buildPaginationSelectorContent = (
    totalPagesCount: number,
    currentPage: number
) => {
    if (totalPagesCount > 6) {
        if (currentPage < 4) {
            if (currentPage == 3) {
                return ["1", "2", "3", "4", "...", `${totalPagesCount}`];
            }
            return ["1", "2", "3", "...", `${totalPagesCount}`];
        } else if (currentPage >= 4 && currentPage + 2 < totalPagesCount) {
            return [
                "1",
                "...",
                `${currentPage - 1}`,
                `${currentPage}`,
                `${currentPage + 1}`,
                "...",
                `${totalPagesCount}`,
            ];
        } else {
            if (currentPage == totalPagesCount - 2) {
                return [
                    "1",
                    "...",
                    `${totalPagesCount - 3}`,
                    `${totalPagesCount - 2}`,
                    `${totalPagesCount - 1}`,
                    `${totalPagesCount}`,
                ];
            }
            return [
                "1",
                "...",
                `${totalPagesCount - 2}`,
                `${totalPagesCount - 1}`,
                `${totalPagesCount}`,
            ];
        }
    } else {
        const pages = [];
        for (let i = 1; i < totalPagesCount + 1; i++) {
            pages.push(`${i}`);
        }
        return pages;
    }
};



export function PaginationSelector({
    currentPage,
    totalPagesCount,
    setPage,
}: {
    currentPage: number;
    totalPagesCount?: number;
    setPage: (num: number) => void;
}) {

    const { t } = useTranslation("evFinder");
    if (!totalPagesCount) return null;

    const PaginationSelectorContent = ({
        currentPage,
        totalPagesCount,
    }: {
        currentPage: number;
        totalPagesCount: number;
    }) => {
        return (
            <div className="flex ml-4">
                {buildPaginationSelectorContent(totalPagesCount, currentPage).map(
                    (p) => {
                        return (
                            <div
                                key={p}
                                onClick={() => {
                                    if (p !== "...") setPage(parseInt(p));
                                }}
                                className={
                                    currentPage == parseInt(p)
                                        ? `bg-Blueberry-dark-default text-white px-1.5 rounded mr-4`
                                        : `text-Grey-shade mr-4 ${p !== "..." ? "cursor-pointer" : ""
                                        }`
                                }
                            >
                                {p}
                            </div>
                        );
                    }
                )}
            </div>
        );
    };

    return (
        <div className="flex justify-between mt-3 sm:mt-0">
            <div className="py-4 text-md text-Grey-shade ">
                {t("pagination.page")} {currentPage} {t("pagination.of")}{" "}
                {totalPagesCount}
            </div>
            <div className="p-4 flex items-center">
                <PaginationArrowLeft disabled={currentPage === 1}
                    onClick={() => {
                        setPage(currentPage - 1);
                    }} />

                <PaginationSelectorContent
                    totalPagesCount={totalPagesCount}
                    currentPage={currentPage}
                />

                <PaginationArrowRight
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === totalPagesCount} />

            </div>
        </div>
    );
}