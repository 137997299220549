import { ReactElement, useMemo } from "react";
import { OfferBanner } from "./components/OfferBanner";
import { VehicleImageWithFallback } from "../../VehicleImageWithFallback";
import { AlternativeEletricVehicleDetails } from "../../../types";


export function VehicleCard({
    cardHeader,
    vehicle,
    cardFooter,
    isActive,
    button,
    additionalActions,
    moreInfoClick,
    hideOfferBanner = false
}: {
    cardHeader: ReactElement | null,
    vehicle?: Partial<AlternativeEletricVehicleDetails>,
    cardFooter?: ReactElement,
    isActive?: boolean,
    button?: ReactElement,
    additionalActions?: ReactElement,
    moreInfoClick?: (e: any) => void,
    hideOfferBanner?: boolean
}) {


    const dimensions = useMemo(() => {
        return {
            cardHeaderHeight: 'h-[50px]',
            bottomPartHeightPercentage: button ? 'h-[170px]' : 'h-[130px]',
            additionalActionsTopOffset: 'top-[180px]'
        }
    }, [button]);


    return (
        <div className={` bg-white border border-Grey-tint rounded overflow-hidden relative min-w-[260px] `}>
            <div className={dimensions.cardHeaderHeight}>
                {cardHeader}
            </div>
            <div className={`${!cardFooter ? 'h-[192px]' : 'h-[170px]'} `}>
                <div className="flex flex-col flex-1 justify-center bg-white items-center h-full w-full">
                    <div

                        className={`p-6 flex justify-center ${!cardFooter ? ' pb-[70px]' : 'pb-12'} w-[260px]`}>
                            <VehicleImageWithFallback
                                onClick={moreInfoClick}
                                className="max-h-32"
                                vehicle={vehicle}
                            />
                    </div>
                </div>
            </div>
            {cardFooter ?
                <div className={`${dimensions.bottomPartHeightPercentage} w-full flex flex-col  ${isActive ? 'bg-Blueberry-dark-default text-white' : 'bg-Grey-background text-Black-default'} justify-between border-t border-t-Grey-tint p-6 pt-8 `}>
                    <div >
                        {cardFooter}
                    </div>
                    <div className="flex w-full justify-center">
                        {button}
                    </div>
                </div>
                : null}

            {additionalActions ?
                <div className={`h-8 max-h-8 overflow-hidden flex w-full absolute ${dimensions.additionalActionsTopOffset} left-0`}>{additionalActions}</div>
                : null}
            {!hideOfferBanner ?
                <OfferBanner offerToDisplay={vehicle?.offerToDisplay} />
                : null}


        </div>
    );


}