import { useState } from "react";
import { Questionnaire } from "./pages/Questionnaire";
import { Welcome } from "./pages/Welcome";
import { Results } from "./pages/Results";
import { Question } from "../types";
import { useTracking } from "../hooks/useTracking";

export function EMobilityQuickCheck() {
    const getCurrentStep = () => {
        if (localStorage.getItem("questionnaireCompleted") === "true") return 2;
        return localStorage.getItem("currentQuestion") ? 1 : 0;
    };

    const { questionnaireEventTrackingWrapper } = useTracking();

    const [step, setStep] = useState(getCurrentStep());

    const steps = [
        {
            component: <Welcome next={() => questionnaireEventTrackingWrapper({
                page: "welcome",
                callback: () => setStep(1)
            })} />
        },
        { component: <Questionnaire showResults={() => setStep(2)} /> },
        {
            component: <Results
                goBack={() => {
                    localStorage.setItem("questionnaireCompleted", "false");
                    localStorage.setItem("currentQuestion", Question.HOME_CHARGING); // For Campaign: Switched from E_MOBILITY
                    setStep(1);
                }}
            />
        },
    ];

    return <div className="flex flex-col flex-1 w-full justify-center items-center">{steps[step].component}</div>;
}
