


export function CloseIcon({
    color = "#6F7582",
    size = 24,
    className,
    onClick,
    onMouseDown
}: {
    color?: string,
    size?: number,
    className?: string,
    onClick?: () => void,
    onMouseDown?: (e: any) => void
}) {

    return (
        <svg className={`${className} cursor-pointer`} onClick={onClick} onMouseDown={onMouseDown} width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon=Cross" clip-path="url(#clip0_30_453)">
                <g id="add 1">
                    <g id="Group">
                        <path id="Vector" d="M5.63574 5.63605L18.3637 18.364" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path id="Vector_2" d="M18.3643 5.63605L5.63634 18.364" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_30_453">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}