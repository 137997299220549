import { CarIconSelector } from "../components/CarIconSelector";

export const getImageServerWithFallback = (): string =>
    !process.env.REACT_APP_BACKEND_URL?.includes("localhost")
      ? (process.env.REACT_APP_BACKEND_URL as string)
      : "https://staging-app.chargylize.com/api/electrify";
  
  
  export const replaceImgWithError = (e: any, vehicleToDisplay: any) => {
    e.target.onerror = null;
    e.target.src = CarIconSelector(
      vehicleToDisplay.bodyStyle,
      vehicleToDisplay.category
    );
    e.target.className = "object-contain";
  };
  
  export function subtractOneMonthFromDate(date: Date): Date {
    // Get the current month and year
    let currentMonth = date.getMonth();
    let currentYear = date.getFullYear();
  
    // Subtract one from the month
    currentMonth--;
  
    // Handle the case where the month is January (0) by adjusting the year
    if (currentMonth < 0) {
      currentMonth = 11; // December (11)
      currentYear--;
    }
  
    // Create a new date with the updated month and year
    const newDate = new Date(currentYear, currentMonth, date.getDate());
  
    return newDate;
  }