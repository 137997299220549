import { useMutation } from "react-query";
import { reportEvent } from "../services";
import { Question, TrackingEvent } from "../types";


export function useTracking() {


    const reportEventMutation = useMutation(['tracking'], (event: TrackingEvent) => {
        return reportEvent(event);
    });

    const questionnaireEventTrackingWrapper = ({ page, callback }: { page: Question | "welcome", callback: () => void }) => {
        callback();
        return reportEventMutation.mutate({
            product: 'quickCheck',
            page,
            action: 'next'
        });
    };

    const evFinderAdTrackingWrapper = ({ callback }: { callback: () => void }) => {
        reportEventMutation.mutate({
            product: 'quickCheck',
            page: 'results',
            action: 'evFinderAd'
        });
        callback();
    }

    const electrificationPlannerAdTrackingWrapper = ({ callback }: { callback: () => void }) => {
        reportEventMutation.mutate({
            product: 'quickCheck',
            page: 'results',
            action: 'electrificationPlannerAd'
        });
        callback();
    }

    const electrifyDriverAdTrackingWrapper = ({ product, callback }: { product: 'evFinder' | 'quickCheck', callback: () => void }) => {
        reportEventMutation.mutate({
            product,
            page: product === 'quickCheck' ? 'results' : '-',
            action: 'electrifyDriverAd'
        });
        callback();
    }


    const evFinderEventTracking = (action: 'filtersUpdated' | 'sortingUpdated' | 'moreInfoRequested') => {
        reportEventMutation.mutate({
            product: 'evFinder',
            page: '-',
            action
        });
    }

    const productLoadEventTracking = (product: 'evFinder' | 'quickCheck') => {
        reportEventMutation.mutate({
            product,
            page: '-',
            action: 'productLoad'
        });
    }

    const offerClickedEventTracking = (product: 'evFinder' | 'quickCheck') => {
        reportEventMutation.mutate({
            product,
            page: product === 'quickCheck' ? 'results' : '-',
            action: 'offerClicked'
        });
    }


    return {
        questionnaireEventTrackingWrapper,
        evFinderAdTrackingWrapper,
        electrificationPlannerAdTrackingWrapper,
        electrifyDriverAdTrackingWrapper,
        evFinderEventTracking,
        productLoadEventTracking,
        offerClickedEventTracking
    }
}