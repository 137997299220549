import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { EMobilityQuickCheck } from "./E-MobilityQuickCheck";
import { EvFinder } from "./EvFinder";
import { EvDetailedView } from "./EvFinder/VehicleList/components/EvDetails";
import { Button } from "./components/Button";
import { ButtonSize, ButtonVariant } from "./components/Button/types";

const queryClient = new QueryClient();

function App() {
    const products = [
        {
            path: "/",
            component: () => (
                <div className="flex flex-col w-full h-screen items-center justify-center">
                    <div className="text-white">
                        Welcome to Chargylize's free products in Development
                        mode. Please select one of the ptoducts.
                    </div>
                    <div className="mt-6 space-x-6">
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            onClick={() =>
                                window.open("/quick-check", "_blank")
                            }
                        >
                            Quick Check
                        </Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            onClick={() => window.open("/ev-finder")}
                        >
                            EV Finder
                        </Button>
                    </div>
                </div>
            ),
        },
        {
            path: "/quick-check",
            component: EMobilityQuickCheck,
        },
        {
            path: "/ev-finder",
            component: EvFinder,
        },
        {
            path: "/ev-finder/ev-details",
            component: EvDetailedView,
        },
    ];

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <div
                    className={`flex flex-col grow w-full min-h-screen ${
                        process.env.REACT_APP_ENV !== "development"
                            ? "bg-transparent"
                            : "bg-Black-background"
                    }`}
                >
                    <Routes>
                        {products.map((dr) => (
                            <Route
                                key={dr.path}
                                path={dr.path}
                                element={<dr.component />}
                            />
                        ))}
                    </Routes>
                    {/* <EMobilityQuickCheck /> */}
                </div>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
