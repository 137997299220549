import { useTranslation } from 'react-i18next';
import { PublicLocation } from '../../../../../types';
import PublicChargersNearHome from '../../../../../assets/poi-search-near-home.webp';
import { ReactComponent as ArrowRange } from '../../../../../assets/arrow-range.svg';
import { ReactComponent as Location } from '../../../../../assets/location.svg';
import { ReactComponent as EvCharger } from '../../../../../assets/ev-charger.svg';

import { ReactNode, useCallback } from 'react';

function ChargeInfoBoxes({
	values,
	distance,
}: {
	values: { locations: number; points: number };
	distance: '250' | '500';
}) {
	const { t } = useTranslation('questionnaire');

	const colorMap = {
		'250': {
			background: 'bg-[#DAC6D8]',
			border: 'border-[#D1B5CB]',
		},
		'500': {
			background: 'bg-[#E3D9E2]',
			border: 'border-[#D9C8D3]',
		},
	};

	const InfoWrapper = useCallback(
		({ children }: { children: ReactNode }) => {
			return (
				<div
					className={`px-5 p-2 flex flex-col items-center ${colorMap[distance].background} border ${colorMap[distance].border} rounded`}
				>
					{children}
				</div>
			);
		},
		[distance],
	);

	return (
		<div>
			<div className="flex items-center mb-1">
				<ArrowRange />
				<div className="ml-2 text-lg">{distance} m</div>
			</div>
			<div className="grid grid-cols-2 gap-2 items-center w-full justify-between">
				<InfoWrapper>
					<div className="flex items-center ml-1">
						<span className="mr-2 font-bold text-xl">{values.locations}</span>
						<Location />
					</div>
					<div className="flex text-[8px] font-bold">{t('chargingPossibilities.chargingLocations')}</div>
				</InfoWrapper>
				<InfoWrapper>
					<div className="flex items-center ml-1">
						<span className="mr-2 font-bold text-xl">{values.points}</span>
						<EvCharger />
					</div>
					<div className="flex text-[8px] font-bold">{t('chargingPossibilities.chargePoints')}</div>
				</InfoWrapper>
			</div>
		</div>
	);
}

export function NearbyChargingPoints({
	coordinates,
	nearbyChargingPoints,
}: {
	coordinates: { lat: number; lon: number } | null;
	nearbyChargingPoints: {
		closerThan250: PublicLocation[];
		closerThan500: PublicLocation[];
	};
}) {
	const { t } = useTranslation('questionnaire');

	const chargingPointsCloserThan250m = nearbyChargingPoints?.closerThan250?.reduce(
		(acc, curr) => acc + curr.countOfEvses,
		0,
	);
	const locationsCloserThan250m = nearbyChargingPoints?.closerThan250?.length;

	const chargingPointsCloserThan500m = nearbyChargingPoints?.closerThan500?.reduce(
		(acc, curr) => acc + curr.countOfEvses,
		0,
	);
	const locationsCloserThan500m = nearbyChargingPoints?.closerThan500?.length;

	return (
		<>
			{coordinates ? (
				<div className="flex flex-col sm:flex-row sm:justify-around md:flex-col w-full h-full bg-Grey-background rounded items-center text-black p-1 py-6">
					<img src={PublicChargersNearHome} />
					<div className="sm:hidden md:block font-black text-xl mt-2">
						{t('chargingPossibilities.availableChargingPoints')}
					</div>
					<div className="mt-3">
						<div className="hidden sm:block md:hidden font-black text-xl mt-2">
							{t('chargingPossibilities.availableChargingPoints')}
						</div>
						<section>
							<ChargeInfoBoxes
								distance={'250'}
								values={{
									locations: locationsCloserThan250m,
									points: chargingPointsCloserThan250m,
								}}
							/>
						</section>
						<section className="mt-3">
							<ChargeInfoBoxes
								distance={'500'}
								values={{
									locations: locationsCloserThan500m - locationsCloserThan250m,
									points: chargingPointsCloserThan500m - chargingPointsCloserThan250m,
								}}
							/>
						</section>
					</div>
				</div>
			) : null}
		</>
	);
}
