import { Button } from "../../../../../components/Button";
import { ButtonSize, ButtonVariant } from "../../../../../components/Button/types";
import { ArrowRightIcon } from "../../../../../components/ArrowRight";
import freeEvFinderImage from "../../../../../assets/marketing_banners/free-ev-finder.webp";
import { useTranslation } from "react-i18next";
import { useTracking } from "../../../../../hooks/useTracking";

export function EVFinderBanner() {
    const { t, i18n } = useTranslation("results");
    const languageCode = i18n.language;


    const { evFinderAdTrackingWrapper } = useTracking();

    return (
        <div className="flex flex-col  relative p-8 items-center justify-center w-full h-fit sm:h-full bg-gradient-to-t from-Blueberry-light-default to-Blueberry-light-shade rounded text-white overflow-hidden">
            <div className="absolute bg-white flex justify-center text-black top-12 right-[-50px] rotate-45 w-56 py-1 shadow-md">
                <div>{t("recommendedVehicles.disclaimer.itsForFree")}</div>
            </div>
            <div className="flex flex-col md:flex-row lg:flex-col h-fit items-center ">
                <img className="w-52" src={freeEvFinderImage} />
                <div className="flex flex-col h-full w-full items-center justify-center ">
                    <div className="text-white text-center mt-4 md:mt-0 lg:mt-4 w-full md:w-2/3 lg:w-full">
                        {t("recommendedVehicles.disclaimer.msg")}{" "}
                        <span className="text-white">{t("recommendedVehicles.disclaimer.evFinder")}</span>
                    </div>
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LARGE}
                        onClick={() => evFinderAdTrackingWrapper({
                            callback: () => window.open(
                                process.env.REACT_APP_ENV === "development"
                                    ? "/ev-finder"
                                    : `${process.env.REACT_APP_WEBSITE_URL}/${languageCode}/drivers/ev-finder/ev-search`,
                                "_blank"
                            ),
                        })}
                        className="mt-6"
                    >
                        <div className="flex items-center justify-between">
                            <span>{t("recommendedVehicles.disclaimer.buttonText")}</span>
                            <ArrowRightIcon className="ml-4" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}
