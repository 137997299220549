import axios from "axios";
import { AlternativeEletricVehicle, AnswerPayload, TrackingEvent, GoogleAutocompletePredictionsResponse } from "../types";
import { AlternativeEletricVehicleDetails, IFilters } from "../electrify_frontend_common/types";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export async function sendQuestionnaireData(data: AnswerPayload) {
  return (await axiosInstance.post("/quick-check/submit-questionnaire", data))
    .data;
}

export async function getAddressCoordinates(query: string) {
  return (
    await axiosInstance.get(`/quick-check/address-coordinates?address=${query}`)
  ).data;
}

export async function getAddressOptions(query: string): Promise<GoogleAutocompletePredictionsResponse[]> {
  return (
    await axiosInstance.get(`/quick-check/address-options?address=${query}`)
  ).data;

}

export async function getChargingPoints(
  mapCorners: {
    southWest: { lat: number; lon: number };
    northEast: { lat: number; lon: number };
  } | null
) {
  if (!mapCorners) return [];
  return (
    await axiosInstance.post("/quick-check/charging-locations", mapCorners)
  ).data;
}

export async function getEvDetails(
  evId: string,
  userCountryCode: string
): Promise<AlternativeEletricVehicleDetails> {
  return (
    await axiosInstance.get(`/ev-finder/ev-details/`, {
      params: {
        evId,
        userCountryCode,
      },
    })
  ).data;
}

export async function getAllBrands(userCountryCode: string) {
  return (
    await axiosInstance.get(`/ev-finder/brands`, {
      params: {
        userCountryCode,
      },
    })
  ).data;
}

export async function getEvs(query: {
  sortBy?: { key: string; order: "ASC" | "DESC" };
  selectedFilters?: IFilters;
  page?: number;
  pageSize?: number;
  userCountryCode: string;
}): Promise<{
  page: number;
  pageSize: number;
  totalPagesCount: number;
  electricVehicles: any[];
  totalVehiclesCount: number;
}> {
  return (await axiosInstance.post(`/ev-finder/all-evs`, query)).data;
}



export async function reportEvent(event: TrackingEvent) {
  return (await axiosInstance.post("/tracking/report-event", event)).data;
}