import { useQuery } from "react-query";
import { getEvDetails } from "../../../../services";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CarIconSelector } from "../../../../E-MobilityQuickCheck/pages/Results/components/EvRecommendations/utils";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { AlternativeEletricVehicleDetails } from "../../../../electrify_frontend_common/types";

export function useEvDetails() {
    const userCountryCode: string = "DE"; // Should be taken from either the IP of user or the country set for the device of user in the future

    const { isMobileView } = useScreenSize();

    const urlQueryParams = new URLSearchParams(useLocation().search);
    const evId = urlQueryParams.get("evId") || "";
    const { i18n, t } = useTranslation("evFinder");
    const localeCode = i18n.language;
    const currencySymbol = "€";

    const [vehicleData, setVehicleData] =
        useState<AlternativeEletricVehicleDetails | null>(null);

    const mainStats = vehicleData
        ? {
              energyConsumption:
                  vehicleData?.evModelData?.technicalData?.vehicleConsumption100Km?.toLocaleString(
                      localeCode
                  ),
              realRange:
                  vehicleData?.evModelData?.range?.expectedRealRangeAverage?.toLocaleString(
                      localeCode
                  ),
              energyCost:
                  vehicleData?.yearlyFuelAndEnergyCosts?.toLocaleString(
                      localeCode
                  ),
              listPrice: vehicleData?.listPrice?.toLocaleString(localeCode),
          }
        : null;

    const headerTitle = vehicleData
        ? `${vehicleData?.make} ${vehicleData?.model}`
        : null;

    const coreData = vehicleData
        ? [
              {
                  prop: t("core-data.availability"),
                  value: vehicleData?.evModelData?.coreData?.isAvailable
                      ? "Yes"
                      : "No",
              },
              {
                  prop: t("core-data.ac-charge-power"),
                  value: vehicleData?.evModelData?.coreData?.acChargePower?.toLocaleString(
                      localeCode
                  ),
                  units: "kW",
              },
              {
                  prop: t("core-data.dc-charge-max-power"),
                  value: vehicleData?.evModelData?.coreData?.dcChargeMaxPower?.toLocaleString(
                      localeCode
                  ),
                  units: "kW",
              },
              // { prop: "Energy comsumption", value: vehicleData?.evModelData?.coreData?.energyConsumption },
          ]
        : null;

    const technicalData = vehicleData
        ? [
              {
                  prop: t("technical-data.acceleration"),
                  value: vehicleData?.evModelData?.technicalData?.acceleration?.toLocaleString(
                      localeCode
                  ),
                  units: "sec",
              },
              {
                  prop: t("technical-data.top-speed"),
                  value: vehicleData?.evModelData?.technicalData?.topSpeed?.toLocaleString(
                      localeCode
                  ),
                  units: "km/h",
              },
              {
                  prop: t("technical-data.total-power"),
                  value: vehicleData?.evModelData?.technicalData?.totalPower?.toLocaleString(
                      localeCode
                  ),
                  units:
                      "kW (" +
                      vehicleData?.evModelData?.technicalData?.totalPowerHp?.toLocaleString(
                          localeCode
                      ) +
                      "PS)",
              },
              {
                  prop: t("technical-data.total-torque"),
                  value: vehicleData?.evModelData?.technicalData?.totalTorque?.toLocaleString(
                      localeCode
                  ),
                  units: "Nm",
              },

              {
                  prop: t("technical-data.drive"),
                  value: vehicleData?.evModelData?.technicalData?.propulsion,
              },
              {
                  prop: t("technical-data.vehicle-consumption"),
                  value: vehicleData?.evModelData?.technicalData?.vehicleConsumption100Km?.toLocaleString(
                      localeCode
                  ),
                  units: "kWh",
              },
          ]
        : null;

    const rangeData = vehicleData
        ? [
              {
                  prop: t("range.expected-real-range"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeAverage?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.expected-real-range-highway-cold"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeHighwayCold?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.expected-real-range-city-cold"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeCityCold?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.expected-real-range-combined-cold"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeCombinedCold?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.expected-real-range-highway-mild"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeHighwayMild?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.expected-real-range-city-mild"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeCityMild?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.expected-real-range-combined-mild"),
                  value: vehicleData?.evModelData?.range?.expectedRealRangeCombinedMild?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.range-wltp-low"),
                  value: vehicleData?.evModelData?.range?.rangeWLTPLow?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
              {
                  prop: t("range.range-wltp-high"),
                  value: vehicleData?.evModelData?.range?.rangeWLTPHigh?.toLocaleString(
                      localeCode
                  ),
                  units: "km",
              },
          ]
        : null;

    const batteryData = vehicleData
        ? [
              {
                  prop: t("battery.nominal-capacity"),
                  value: vehicleData?.evModelData?.battery?.nominalCapacity?.toLocaleString(
                      localeCode
                  ),
                  units: "kWh",
              },
              {
                  prop: t("battery.useable-capacity"),
                  value: vehicleData?.evModelData?.battery?.usableCapacity?.toLocaleString(
                      localeCode
                  ),
                  units: "kWh",
              },
              {
                  prop: t("battery.battery-type"),
                  value: vehicleData?.evModelData?.battery?.batteryType,
              },
          ]
        : null;

    const chargingData = vehicleData
        ? [
              {
                  prop: t("charging.ac-charge-port"),
                  value: vehicleData?.evModelData?.charging?.acChargePort,
              },
              {
                  prop: t("charging.ac-port-location"),
                  value: vehicleData?.evModelData?.charging?.acPortLocation,
              },
              {
                  prop: t("charging.ac-charge-power"),
                  value: vehicleData?.evModelData?.charging?.acChargePower?.toLocaleString(
                      localeCode
                  ),
                  units: "kW",
              },
              {
                  prop: t("charging.ac-charge-time"),
                  value: vehicleData?.evModelData?.charging?.acChargeTime?.toLocaleString(
                      localeCode
                  ),
                  units: "min",
              },
              {
                  prop: t("charging.dc-charge-port"),
                  value: vehicleData?.evModelData?.charging?.dcChargePort,
              },
              {
                  prop: t("charging.dc-port-location"),
                  value: vehicleData?.evModelData?.charging?.dcPortLocation,
              },
              {
                  prop: t("charging.dc-charge-power"),
                  value: vehicleData?.evModelData?.charging?.dcChargePower?.toLocaleString(
                      localeCode
                  ),
                  units: "kW",
              },
              {
                  prop: t("charging.dc-charge-time"),
                  value: vehicleData?.evModelData?.charging?.dcChargeTime?.toLocaleString(
                      localeCode
                  ),
                  units: "min",
              },
          ]
        : null;

    const dimensionsAndWeightData = vehicleData
        ? [
              {
                  prop: t("dimensions-and-weight.length"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.length?.toLocaleString(
                      localeCode
                  ),
                  units: "mm",
              },
              {
                  prop: t("dimensions-and-weight.width"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.width?.toLocaleString(
                      localeCode
                  ),
                  units: "mm",
              },
              {
                  prop: t("dimensions-and-weight.width-with-mirrors"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.widthWithMirrors?.toLocaleString(
                      localeCode
                  ),
                  units: "mm",
              },
              {
                  prop: t("dimensions-and-weight.height"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.height?.toLocaleString(
                      localeCode
                  ),
                  units: "mm",
              },
              {
                  prop: t("dimensions-and-weight.wheelbase"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.wheelbase?.toLocaleString(
                      localeCode
                  ),
                  units: "mm",
              },
              {
                  prop: t("dimensions-and-weight.weight-unladen"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.weightUnladen?.toLocaleString(
                      localeCode
                  ),
                  units: "kg",
              },
              {
                  prop: t("dimensions-and-weight.gross-vehicle-weight"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.grossVehicleWeight?.toLocaleString(
                      localeCode
                  ),
                  units: "kg",
              },
              {
                  prop: t("dimensions-and-weight.max-payload"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.maxPayload?.toLocaleString(
                      localeCode
                  ),
                  units: "kg",
              },
              {
                  prop: t("dimensions-and-weight.cargo-volume"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.cargoVolume?.toLocaleString(
                      localeCode
                  ),
                  units: "l",
              },
              {
                  prop: t("dimensions-and-weight.cargo-volume-max"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.cargoVolumeMax?.toLocaleString(
                      localeCode
                  ),
                  units: "l",
              },
              {
                  prop: t("dimensions-and-weight.cargo-volume-frunk"),
                  value: vehicleData?.evModelData?.dimensionsAndWeight?.cargoVolumeFrunk?.toLocaleString(
                      localeCode
                  ),
                  units: "l",
              },
          ]
        : null;

    const miscellaneousData = vehicleData
        ? [
              {
                  prop: t("miscellaneous.tow-hitch-possible"),
                  value: vehicleData?.evModelData?.miscellaneous
                      ?.towHitchPossible
                      ? "Yes"
                      : "No",
              },
              {
                  prop: t("miscellaneous.seats"),
                  value: vehicleData?.evModelData?.miscellaneous?.seats,
              },
              {
                  prop: t("miscellaneous.isofix"),
                  value: vehicleData?.evModelData?.miscellaneous?.isofixSeats
                      ? "Yes, " +
                        vehicleData?.evModelData?.miscellaneous?.isofixSeats +
                        " Seats"
                      : "No",
              },
              {
                  prop: t("miscellaneous.turning-circle"),
                  value: vehicleData?.evModelData?.miscellaneous?.turningCircle?.toLocaleString(
                      localeCode
                  ),
                  units: "m",
              },
          ]
        : null;

    useQuery([], () => getEvDetails(evId, userCountryCode || "DE"), {
        retry: false,
        onSuccess: (data) => {
            setVehicleData(data);
        },
        onError: (error) => {
            setVehicleData(null);
        },
    });

    const replaceImgWithError = (e: any) => {
        e.target.onerror = null;
        e.target.src = CarIconSelector(
            vehicleData?.bodyStyle,
            vehicleData?.category
        );
    };

    return {
        isMobileView,
        coreData,
        technicalData,
        rangeData,
        batteryData,
        chargingData,
        dimensionsAndWeightData,
        miscellaneousData,
        mainStats,
        headerTitle,
        currencySymbol,
        vehicleData,
        replaceImgWithError,
        t,
    };
}
