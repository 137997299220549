import { useQuery } from "react-query";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { RefObject, useEffect, useMemo, useState } from "react";
import { MenuProps, SearchInput, SelectedBrand } from "./components";
import { getAllBrands } from "../../../../../../services";
import { Spinner } from "../../../../../Spinner";


export function MakeDropdown({
  value,
  updateValue,
  backgroundColor,
  ref
}: {
  value: string[];
  updateValue: (value: string[]) => void;
  backgroundColor: "light" | "dark";
  ref?: RefObject<HTMLDivElement>;
}) {
  const userCountryCode: string = "DE"; // Should be taken from either the IP of user or the country set for the device of user in the future

  const { data: availableOptions } = useQuery(['brands'], () => getAllBrands(userCountryCode || "DE"));


  const handleOnChangeDropdown = (newValue: string[]) => {
    if (value?.includes("All") && !newValue.includes("All")) {
      /* When the User select all and all is not selected, then it return empty array [] */
      updateValue([]);
    } else if (!value?.includes("All") && newValue.includes("All")) {
      /* If "All" is not selected and now "All" is selected, the app takes "All" as the new value */
      updateValue(["All"]);
    } else if (
      value?.includes("All") &&
      newValue.includes("All") &&
      newValue.length > 1
    ) {
      /* When the user selects an option the option "All" is already selected */
      updateValue([...availableOptions?.filter((item: any) => !newValue.includes(item))]);
    } else {
      if (
        availableOptions?.filter((item: any) => newValue.includes(item))?.length ===
        availableOptions?.length
      ) {
        /* When the user selects all options then All is selected */
        updateValue(["All"]);
      } else {
        updateValue([...newValue]);
      }
    }
  };

  const [options, setOptions] = useState(availableOptions);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    setOptions(
      availableOptions?.filter((option: any) =>
        option.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [filter, availableOptions?.length]);

  const MenuItemStyles = useMemo(() => ({
    fontSize: "12px",
    color: backgroundColor === "dark" ? "white" : "#1e1e21",
    backgroundColor: backgroundColor === "dark" ? "#1e1e21" : "white",
    ":hover": {
      color: "white",
      backgroundColor: "#78194F",
    },
    "&.Mui-selected": {
      backgroundColor: backgroundColor === "dark" ? "#1e1e21" : "white",
    },
    "&.Mui-selected:hover": {
      color: backgroundColor === "dark" ? "white" : "#1e1e21",
      backgroundColor: "white",
    },
  }), [backgroundColor])


  if (!availableOptions) return <Spinner />;

  return (
    <>
      <Select
        ref={ref}
        multiple
        autoComplete="off"
        autoFocus={false}
        value={value}
        onChange={(e: any) => handleOnChangeDropdown(e.target.value)}
        renderValue={(selected: string[]) =>
          selected.length > 0 && !selected.includes("All") ? (
            <div className="flex flex-wrap max-h-32 overflow-y-auto no-scrollbar">
              {selected.map((s) => (
                <SelectedBrand brand={s} value={value} handleOnChangeDropdown={handleOnChangeDropdown} />
              ))}
            </div>
          ) : (
            <div className="text-xs">
              <div className="pt-1">All</div>
            </div>
          )
        }
        input={
          <OutlinedInput
            sx={{
              fontSize: "12px",
              color: backgroundColor === "dark" ? "white" : "black",
              maxHeight: "100px",
              overflow: "scroll",
            }}
          />
        }
        displayEmpty={true}
        MenuProps={MenuProps(backgroundColor)}
        className="w-full text-xs mb-4 "
        label="Select brand"
        defaultChecked={!!value}
        sx={{
          color: backgroundColor === "dark" ? "white" : "black",
          backgroundColor: "transparent",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: backgroundColor === "dark" ? "white" : "",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: backgroundColor === "dark" ? "white" : "",
          },
          "& .MuiSvgIcon-root": {
            color: backgroundColor === "dark" ? "white" : "",
          },
        }}
      >
        <SearchInput filter={filter} setFilter={setFilter} />
        <MenuItem
          sx={MenuItemStyles}
          key={"All"}
          value={"All"}
        >
          <div className="flex items-center my-2">
            <input
              className="w-5 h-5 cursor-pointer text-Blueberry-dark-default bg-white rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
              type="checkbox"
              checked={value?.includes("All")}
            />
            <span className="ml-4">{"All"}</span>
          </div>
        </MenuItem>
        {options?.map((name: any) => (
          <MenuItem
            sx={MenuItemStyles}
            key={name}
            value={name}
          >
            <div className="flex items-center my-2">
              <input
                className="w-5 h-5 cursor-pointer text-Blueberry-dark-default bg-white rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                type="checkbox"
                checked={value?.includes("All") || value?.includes(name)}
              />
              <span className="ml-4">{name}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
